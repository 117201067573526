import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NotFound.css';
import { FaArrowLeft } from 'react-icons/fa';

const NotFound = () => {
  const handleRedirect = () => {
    window.location.href = 'https://qless.site';
  };

  return (
    <div className="not-found-container text-center">
      <img src="/qless-logo.png" alt="Not Found" className="not-found-image" />
      <div className="not-found-title">Page Not Found</div>
      <div className="not-found-text">The page you are trying to access does not exist.</div>
      <button className="btn btn-green" onClick={handleRedirect}>
        <FaArrowLeft className="icon-spacing" /> Back to Main Site
      </button>
    </div>
  );
};

export default NotFound;