import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import Sidebar from './Sidebar';
import DashboardNavbar from './Navbar';
import FilterDropdown from './FilterDropdown';
import QueueTable from './QueueTable';
import './Dashboard.css';
import axios from 'axios';
import { io } from 'socket.io-client'; // Import Socket.IO client

const Dashboard = ({ user }) => {
  const [windowNum] = useState(user.windowNum);
  const [department, setDepartment] = useState('');
  const [document, setDocument] = useState('');
  const [queueData, setQueueData] = useState([]);
  const [totalQueue, setTotalQueue] = useState(0);
  const [totalServed, setTotalServed] = useState(0); // Track total served count
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isHold, setIsHold] = useState(false);
  // const [isDoneButtonDisabled, setIsDoneButtonDisabled] = useState(true);
  const [alert, setAlert] = useState(null);
  const [servingQueue, setServingQueue] = useState(null); // Track if a window is already serving a queue
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const staffSocket = useRef(null); // Staff WebSocket
  const kioskSocket = useRef(null); // Kiosk App WebSocket
  const queueTrackerSocket = useRef(null); // Queue Tracker WebSocket

  // Fetch department options
  useEffect(() => {
    // Fetch department options from the backend
    axios.get('https://qless-server.onrender.com/api/departments', {
      withCredentials: true, // Include cookies with request for session-based auth
    })
    .then(response => {
      // Transform the response to the desired format
      const options = response.data.reduce((acc, curr) => {
        if (!acc[curr.window_id]) {
          acc[curr.window_id] = [];
        }
        acc[curr.window_id].push({ value: curr.acronym, label: curr.acronym });
        return acc;
      }, {});

      // Add a combined option for each window_id group
      for (const windowId in options) {
        const combinedValue = options[windowId].map(option => option.value).join(',');
        const combinedLabel = options[windowId].map(option => option.label).join(' / ');
        options[windowId].unshift({ value: combinedValue, label: combinedLabel });
      }

      setDepartmentOptions(options);
    })
    .catch(error => {
      console.error('There was an error fetching the department options!', error);
    });
  }, []); // This effect runs only once on mount


  // Establish WebSocket connections on mount
  useEffect(() => {
    // Connect to the Staff backend WebSocket (port 3000)
    staffSocket.current = io('https://qless-server.onrender.com', {
      withCredentials: true, // Include cookies for session-based authentication
    });
  
    // Connect to the Queue Tracker backend WebSocket (port 3004)
    queueTrackerSocket.current = io('https://qless-live-queueing-tracker.onrender.com');
  
    // Connect to the Kiosk App backend WebSocket (port 3001)
    kioskSocket.current = io('https://qless-kiosk-application.onrender.com');
  
    // Listen for queue updates from the Staff backend (queue being served, etc.)
    staffSocket.current.on('queueUpdate', (updatedQueue) => {
      console.log('Received queue update from Staff backend:', updatedQueue);
      // Fetch the updated queue data from the backend
      fetchQueueData();
      setQueueData((prevQueueData) => {
        // Filter out the queue with the same queueNumber
        const filteredData = prevQueueData.filter(queue => queue.queueNumber !== updatedQueue.queueNumber);
        if (updatedQueue.status !== 'done' && updatedQueue.status !== 'canceled') {
          // Add the updated queue back if it's not done or canceled
          return [...filteredData, updatedQueue];
        }
        return filteredData;
      });
    });
  
    // Listen for queue cancellations from the Queue Tracker backend
    queueTrackerSocket.current.on('queueCanceled', (data) => {
      console.log('Received queue cancellation from Queue Tracker backend:', data.queueNumber);
      setQueueData(prevQueueData => prevQueueData.filter(queue => queue.queueNumber !== data.queueNumber));
    });
  
    // Listen for new queues added from the Kiosk App backend
    kioskSocket.current.on('newQueueAdded', (newQueue) => {
      console.log('Received new queue from Kiosk App backend:', newQueue);
      // Fetch the updated queue data from the backend
      fetchQueueData();
      setQueueData((prevQueueData) => {
        // Check if the queue already exists based on queueNumber
        const queueExists = prevQueueData.some(queue => queue.queueNumber === newQueue.queueNumber);
  
        if (!queueExists) {
          // If it doesn't exist, add the new queue to the list
          return [...prevQueueData, newQueue];
        }
  
        // If the queue exists, return the previous state unchanged
        return prevQueueData;
      });
    });
  
    return () => {
      staffSocket.current.disconnect();  // Clean up Staff WebSocket connection on component unmount
      queueTrackerSocket.current.disconnect();  // Clean up Queue Tracker WebSocket connection on component unmount
      kioskSocket.current.disconnect();  // Clean up Kiosk App WebSocket connection on component unmount
    };
  }, []);
  
  useEffect(() => {
    // Update the total queue count whenever queueData changes
    setTotalQueue(queueData.length);
  }, [queueData]);

  // Document options based on the provided categories
  const documentOptions = [
    { value: 'Certification of Grades', label: 'Certification of Grades' },
    { value: 'Certification of Attendance', label: 'Certification of Attendance' },
    { value: 'Certification of Candidacy for Graduation', label: 'Certification of Candidacy for Graduation' },
    { value: 'Certification of Course Description', label: 'Certification of Course Description' },
    { value: 'Certification of Eligibility to Transfer', label: 'Certification of Eligibility to Transfer' },
    { value: 'Certification of Enrollment', label: 'Certification of Enrollment' },
    { value: 'Certification of Graduation and Awards Received', label: 'Certification of Graduation and Awards Received' },
    { value: 'Certification of English as Medium of Instruction', label: 'Certification of English as Medium of Instruction' },
    { value: 'Certification of NSTP Serial Number', label: 'Certification of NSTP Serial Number' },
    { value: 'Certification of General Weighted Average (GWA)', label: 'Certification of General Weighted Average (GWA)' },
    { value: 'Certification of Special Order Number (S.O. No.)', label: 'Certification of Special Order Number (S.O. No.)' },
    { value: 'Certification of Students Ranking', label: 'Certification of Students Ranking' },
    { value: 'Certification of Units Earned', label: 'Certification of Units Earned' },
    { value: 'Certification of Program Recognition/Compliance', label: 'Certification of Program Recognition/Compliance' },
    { value: 'Cross Enrollment Permit', label: 'Cross Enrollment Permit' },
    { value: 'Transcript of Records (TOR)', label: 'Transcript of Records (TOR)' },
    { value: 'Authentication of School Records', label: 'Authentication of School Records' },
    { value: 'Certification, Authentication, Verification (CAV)', label: 'Certification, Authentication, Verification (CAV)' },
    { value: 'Second Copy of Transfer Credentials', label: 'Second Copy of Transfer Credentials' },
    { value: 'Second Copy of Diploma', label: 'Second Copy of Diploma' },
    { value: 'CTC', label: 'Certificate of True Copy' }
  ];

  const fetchQueueData = useCallback(() => {
    axios.get('https://qless-server.onrender.com/api/queue', {
      params: { windowNum, department, document },
      withCredentials: true, // Include cookies with request for session-based auth
    })
    .then(response => {
      setQueueData(response.data);
      setTotalQueue(response.data.length);
    })
    .catch(error => {
      console.error('There was an error fetching the queue data!', error);
    });

    // Fetch the currently serving queue for this window
    axios.get('https://qless-server.onrender.com/api/currentQueue', {
      withCredentials: true, // Include cookies with request
    })
    .then(response => {
      const serving = response.data.find(queue => queue.windowNum === parseInt(windowNum));
      setServingQueue(serving);
    })
    .catch(error => {
      console.error('There was an error fetching the current serving queue!', error);
    });

    // Fetch the total served count for this window
    axios.get('https://qless-server.onrender.com/api/totalServed', {
      params: { windowNum },
      withCredentials: true, // Include cookies with request
    })
    .then(response => {
      setTotalServed(response.data.totalServed);
    })
    .catch(error => {
      console.error('There was an error fetching the total served count!', error);
    });
  }, [windowNum, department, document]);

  useEffect(() => {
    fetchQueueData();
  }, [fetchQueueData]);

  const [isHoldButtonDisabled, setIsHoldButtonDisabled] = useState(true); // Track if the Hold button is disabled

  const handleRowClick = (row) => {
    setSelectedTransaction(row);
    setIsHold(row.status === 'hold'); // Set the hold state based on the row's status
    setIsHoldButtonDisabled(row.status !== 'serving'); // Enable Hold button only if the status is "serving"
  };

  // New function to show alert with a timeout and progress indicator
  const showAlert = (type, message, duration = 9000) => {
    setAlert({ type, message, duration, progress: 100 });
    let interval;

    // Start the countdown
    interval = setInterval(() => {
      setAlert((prevAlert) => {
        if (!prevAlert) return null;
        const newProgress = prevAlert.progress - (100 / (duration / 100));
        if (newProgress <= 0) {
          clearInterval(interval);
          return null;
        }
        return { ...prevAlert, progress: newProgress };
      });
    }, 100);
  };

  const handleCall = () => {
    if (servingQueue) {
      showAlert('danger', `There is already a queue being served at Window ${windowNum}. Complete or cancel the current queue before calling a new one.`);
      return;
    }
  
    if (selectedTransaction) {
      axios.post('https://qless-server.onrender.com/api/updateServing', {
        queueId: selectedTransaction.queueId,
        windowNum
      }, {
        withCredentials: true, // Include cookies with request
      })
      .then(response => {
        console.log('Queue status updated to serving:', response.data);
        showAlert('success', `Queue number ${selectedTransaction.queueNumber} is now being served at Window ${windowNum}.`);
        setServingQueue(selectedTransaction); // Keep track of the serving queue
        setSelectedTransaction({ ...selectedTransaction, status: 'serving' }); // Update the status locally
        setIsHoldButtonDisabled(false);
        // setIsDoneButtonDisabled(false); // Enable Done button
        fetchQueueData(); // Refresh the queue data to update the serving queue
      })
      .catch(error => {
        console.error('There was an error updating the queue!', error);
        showAlert('danger', 'There was an error updating the queue!');
      });
    }
  };
  

  const handleDone = () => {
    if (selectedTransaction) {
      const status = isHold ? 'cancel' : 'done';  // Determine the status based on hold state
      const endpoint = status === 'cancel' ? 'updateCancel' : 'updateDone'; // Choose the endpoint
  
      axios.post(`https://qless-server.onrender.com/api/${endpoint}`, {
        queueId: selectedTransaction.queueId,
        status  // Send the status (only needed for 'done')
      }, {
        withCredentials: true, // Include cookies with request
      })
      .then(response => {
        console.log(`Queue status updated to ${status}:`, response.data);
  
        // Different alert messages based on 'status'
        const message = status === 'done'
          ? `Queue number ${selectedTransaction.queueNumber} has been marked as complete.`
          : `Queue number ${selectedTransaction.queueNumber} has been canceled.`;
  
        showAlert(status === 'done' ? 'success' : 'danger', message);
        
        setServingQueue(null); // Clear the serving queue
        setSelectedTransaction(null);
        setIsHoldButtonDisabled(true);
        // setIsDoneButtonDisabled(true);
        fetchQueueData(); // Refresh the queue data to reflect the changes
      })
      .catch(error => {
        console.error('There was an error updating the queue!', error);
        showAlert('danger', 'There was an error updating the queue!');
      });
    }
  };
  
  // Handle Hold
  const handleHold = () => {
    if (selectedTransaction) {
      const endpoint = isHold ? 'updateResume' : 'updateHold'; // Toggle between hold and resume
  
      axios.post(`https://qless-server.onrender.com/api/${endpoint}`, {
        queueId: selectedTransaction.queueId
      }, {
        withCredentials: true, // Include cookies with request
      })
      .then(response => {
        console.log(`Queue status updated to ${isHold ? 'pending' : 'hold'}:`, response.data);
  
        // Different alert messages based on hold state
        const message = isHold
          ? `Queue number ${selectedTransaction.queueNumber} has been resumed.`
          : `Queue number ${selectedTransaction.queueNumber} is now on hold.`;
  
        showAlert(isHold ? 'info' : 'warning', message);
  
        setIsHold(!isHold); // Toggle the hold state
        setIsHoldButtonDisabled(!isHold); // Disable the Hold button if resuming
        if (isHold) {
          setSelectedTransaction({ ...selectedTransaction, status: 'pending' }); // Update the local state to 'pending'
        }
        fetchQueueData(); // Refresh the queue data to reflect the hold status
      })
      .catch(error => {
        console.error('There was an error updating the queue!', error);
        showAlert('danger', 'There was an error updating the queue!');
      });
    }
  };  

  return (
    <Container fluid className="dashboard-container">
      <Row noGutters>
        <Col md={2} className="p-0">
          <Sidebar windowNum={windowNum} />
        </Col>
        <Col md={10} className="p-0">
          <DashboardNavbar />
          <Container fluid className="dashboard-content">
            {alert && (
              <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
                {alert.message}
                <div className="alert-progress-bar">
                <div
                  className={`alert-progress ${alert.type}`}
                  style={{ width: `${alert.progress}%` }}
                ></div>
                </div>
              </Alert>
            )}
            <Row className="mt-4">
              <Col>
                <FilterDropdown 
                  label="Progam/Course" 
                  options={departmentOptions[user.windowNum]}
                  onChange={(e) => setDepartment(e.target.value)}
                />
              </Col>
              <Col>
                <FilterDropdown 
                  label="Document" 
                  options={documentOptions}  // Added document options here
                  onChange={(e) => setDocument(e.target.value)} 
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={9}>
                <div className="table-containers">
                  <QueueTable 
                    data={queueData} 
                    onRowClick={handleRowClick} 
                    selectedTransaction={selectedTransaction} 
                  />
                </div>
              </Col>
              <Col md={3} className="summary">
                <div className="summary-item">
                  <h4>TOTAL QUEUE: {totalQueue}</h4>
                </div>
                {selectedTransaction ? (
                  <>
                    <div className="summary-item dash-queue-number">
                      <h1>{selectedTransaction.queueNumber}</h1>
                    </div>
                    <div className="summary-item button-group">
                      {!isHold ? (
                        <>
                          <Button variant="warning" onClick={handleCall}>CALL</Button>
                          <Button variant="danger" onClick={handleHold} disabled={isHoldButtonDisabled}>HOLD</Button>
                          <Button variant="success" onClick={handleDone} >DONE</Button>
                        </>
                      ) : (
                        <>
                          <Button variant="primary" onClick={handleHold}>RESUME</Button>
                          <Button variant="danger" onClick={handleDone}>CANCEL</Button>
                        </>
                      )}
                    </div>
                    <div className="summary-item">
                      <h4>PURPOSE:</h4>
                      <p>{selectedTransaction.service}</p>
                    </div>
                  </>
                ) : (
                  <div className="summary-item">
                    <h4>Select a transaction</h4>
                  </div>
                )}
                <div className="summary-item">
                  <h4>TOTAL SERVED: {totalServed}</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
