import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import Sidebar from '../Sidebar';
import DashboardNavbar from '../Navbar';
import axios from 'axios';
import './TransactionDays.css';

const TransactionDays = ({ user }) => {
  const [transactionDays, setTransactionDays] = useState([]);
  const [alert, setAlert] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Track if the form is in edit mode

  useEffect(() => {
    fetchTransactionDays();
  }, []);

  const fetchTransactionDays = async () => {
    try {
      const response = await axios.get('https://qless-server.onrender.com/api/transactionDays', {
        withCredentials: true,
      });
      setTransactionDays(response.data);
    } catch (error) {
      console.error('Error fetching transaction days:', error);
      setAlert({ type: 'danger', message: 'Failed to load transaction days.' });
    }
  };

  const handleAddField = () => {
    setTransactionDays([...transactionDays, { dayPattern: '', description: '' }]); // Omit id for new fields
  };

  const handleChange = (index, field, value) => {
    const updatedDays = [...transactionDays];
    updatedDays[index][field] = value;
    setTransactionDays(updatedDays);
  };

  const handleDeleteField = async (id, index) => {
    if (id) {
      try {
        await axios.delete(`https://qless-server.onrender.com/api/transactionDays/${id}`, {
          withCredentials: true,
        });
        setAlert({ type: 'success', message: 'Transaction day deleted successfully!' });
      } catch (error) {
        console.error('Error deleting transaction day:', error);
        setAlert({ type: 'danger', message: 'Failed to delete transaction day.' });
      }
    }

    // Remove the field from the frontend state
    setTransactionDays(transactionDays.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    try {
      console.log('Saving transaction days:', transactionDays);
  
      // Separate new entries from existing ones
      const [existingDays, newDays] = transactionDays.reduce(
        ([existing, news], day) => {
          if (day.id) {
            existing.push(day); // Existing records with IDs
          } else {
            news.push({ dayPattern: day.dayPattern, description: day.description }); // New records without IDs
          }
          return [existing, news];
        },
        [[], []]
      );
  
      // Send new entries via POST
      if (newDays.length > 0) {
        await axios.post('https://qless-server.onrender.com/api/transactionDays', newDays, {
          withCredentials: true,
        });
      }
  
      // Send existing entries via PUT
      if (existingDays.length > 0) {
        await axios.put('https://qless-server.onrender.com/api/transactionDays', existingDays, {
          withCredentials: true,
        });
      }
  
      setAlert({ type: 'success', message: 'Transaction days saved successfully!' });
      setIsEditing(false); // Disable editing after saving
      fetchTransactionDays(); // Refresh the list
    } catch (error) {
      console.error('Error saving transaction days:', error);
      setAlert({ type: 'danger', message: 'Failed to save transaction days.' });
    }
  };
  

  const toggleEditMode = () => {
    setIsEditing(!isEditing); // Toggle between edit and view mode
  };

  return (
    <Container fluid className="transaction-container">
      <Row noGutters>
        <Col md={2} className="p-0">
          <Sidebar role={user.role} />
        </Col>
        <Col md={10} className="p-0">
          <DashboardNavbar />
          <div className="transaction-form-section">
            <h2 className="transaction-title">Manage Transaction Days</h2>
            {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
            <Form className="transaction-form">
              {transactionDays.map((day, index) => (
                <Row key={index} className="mb-3">
                  <Col md={5}>
                    <Form.Group controlId={`dayPattern-${index}`} className="form-group">
                      <Form.Label>Day Pattern</Form.Label>
                      <Form.Control
                        type="text"
                        name="dayPattern"
                        value={day.dayPattern}
                        onChange={(e) => handleChange(index, 'dayPattern', e.target.value)}
                        placeholder="e.g., M/W/F"
                        disabled={!isEditing}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group controlId={`description-${index}`} className="form-group">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={day.description}
                        onChange={(e) => handleChange(index, 'description', e.target.value)}
                        placeholder="Enter description"
                        disabled={!isEditing}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2} className="d-flex align-items-center">
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteField(day.id, index)}
                      disabled={!isEditing}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              ))}
              {isEditing && (
                <Button variant="success" onClick={handleAddField} className="mb-3">
                  Add New Field
                </Button>
              )}
              <div className="form-buttons">
                {isEditing ? (
                  <>
                    <Button variant="secondary" onClick={toggleEditMode}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </>
                ) : (
                  <Button variant="success" onClick={toggleEditMode}>
                    Edit
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TransactionDays;
