import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Login from './Login';
import Dashboard from './components/Dashboard';
import Transaction from './components/Transaction';
import ManageDisplay from './components/ManageDisplay';
import LiveQueueingDisplay from './components/LiveQueueingDisplay';
import AdminDashboard from './components/admin/AdminDashboard';
import UserAccounts from './components/admin/UserAccounts';
import ActivityMonitoringScreen from './components/admin/ActivityMonitoringScreen';
import AdminRecords from './components/admin/AdminRecords';
import AdminManageDisplay from './components/admin/AdminManageDisplay';
import TransactionDaysForm from './components/admin/TransactionDaysForm';
import NotFound from './components/NotFound';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (storedUser && token) {
      setUser(JSON.parse(storedUser));
    } else {
      checkSession();
    }
    setLoading(false);
  }, []);

  const checkSession = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;
    try {
      const response = await axios.get('https://qless-server.onrender.com/api/checkSession', {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      });
      setUser(response.data.user);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    } catch (error) {
      console.log('Session expired');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    }
  };
  
  const handleLogin = (userData, token) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('token', token);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={<Navigate to="/login" replace />} 
        />
        <Route 
          path="/login" 
          element={user ? <Navigate to={user.role === 'Admin' ? '/admin/dashboard' : '/dashboard'} /> : <Login onLogin={handleLogin} />} 
        />
        <Route 
          path="/dashboard" 
          element={user ? <Dashboard user={user} /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin/dashboard" 
          element={user && user.role === 'Admin' ? (
            <AdminDashboard user={user} />
          ) : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin/user-accounts" 
          element={user && user.role === 'Admin' ? (
            <UserAccounts user={user} />
          ) : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin/activity-monitoring-screen" 
          element={user && user.role === 'Admin' ? (
            <ActivityMonitoringScreen user={user} />
          ) : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin/records" 
          element={user && user.role === 'Admin' ? (
            <AdminRecords user={user} />
          ) : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin/manage-display" 
          element={user && user.role === 'Admin' ? (
            <AdminManageDisplay user={user} />
          ) : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin/transaction-days" 
          element={user && user.role === 'Admin' ? (
            <TransactionDaysForm user={user} />
          ) : <Navigate to="/login" />} 
        />
        <Route 
          path="/transaction" 
          element={user ? <Transaction user={user} /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/manage-display" 
          element={user ? <ManageDisplay user={user} /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/live-queueing-display" 
          element={<LiveQueueingDisplay />} 
        />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
      </Routes>
    </Router>
  );
};

export default App;
