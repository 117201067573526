import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert, Card, InputGroup } from 'react-bootstrap';
import { FaUser, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons

import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://qless-server.onrender.com/login',
        { username, password },
        { withCredentials: true }
      );
      onLogin(response.data.user, response.data.token);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setError('Your account is inactive. Please contact the administrator.');
      } else {
        setError('Invalid credentials');
      }
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container fluid className="login-container">
      <Row className="align-items-center justify-content-center h-100">
        <Col md={7} className="login-background"></Col>
        <div className="overlay"></div>
        <Col md={5} className="login-form-container">
          <Card className="card-login">
            <Card.Body>
              <div className="logo-container-login text-center">
                <img src={`${process.env.PUBLIC_URL}/qless-logo.png`} alt="QLess Logo" className="qless-logo" />
              </div>
              <div className="login-heading">
                <h3 className="text-center">REGISTRAR LOGIN</h3>
              </div>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formUsername" className="mb-3">
                  <InputGroup>
                    <InputGroup.Text><FaUser /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-3 position-relative">
                  <InputGroup>
                    <InputGroup.Text><FaLock /></InputGroup.Text>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="login-password-input"
                    />
                  </InputGroup>
                  <span 
                    onClick={togglePasswordVisibility} 
                    className="login-password-toggle-icon"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </Form.Group>
                <Button variant="success" type="submit" className="mt-3 w-100">
                  Login
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;