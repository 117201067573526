import React, { useState, useEffect, useRef } from 'react';
import { Table, Form, Row, Col, Button, Modal } from 'react-bootstrap'; // Import Modal from react-bootstrap
import axios from 'axios';
import FilterDropdown from './FilterDropdown';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './Transaction.css';
import { CSVLink } from 'react-csv'; // CSV export functionality

const TransactionTable = ({ windowNum }) => {
  const [transactions, setTransactions] = useState([]);
  const [name, setName] = useState('');
  const [department, setDepartment] = useState('');
  const [service, setService] = useState('');
  const [date, setDate] = useState('');
  
  // Modal state
  const [showModal, setShowModal] = useState(false);

  // Open and close modal functions
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  
  const tableRef = useRef(); // To reference the table for PDF generation

  useEffect(() => {
    const fetchTransactions = async () => {
      const params = {
        windowNum: windowNum || undefined,
        name: name || undefined,
        department: department || undefined,
        service: service || undefined,
        date: date || undefined,
      };

      try {
        const response = await axios.get('https://qless-server.onrender.com/api/transactions', {
          params,
          withCredentials: true,  // Send session cookies along with the request
        });
        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transaction data:', error);
      }
    };

    fetchTransactions();
  }, [windowNum, name, department, service, date]);

  const departmentOptions = {
    1: [
      { value: 'BSN,BSA,BSBA', label: 'BSN, BSA, BSBA' },
      { value: 'BSN', label: 'BSN' },
      { value: 'BSA', label: 'BSA' },
      { value: 'BSBA', label: 'BSBA' }
    ],
    2: [
      { value: 'BSIE,BSECE,BSCpE', label: 'BSIE, BSECE, BSCpE' },
      { value: 'BSIE', label: 'BSIE' },
      { value: 'BSECE', label: 'BSECE' },
      { value: 'BSCpE', label: 'BSCpE' }
    ],
    3: [
      { value: 'TECHVOC,ASSOCIATE PROGRAM,BSPsych,Graduate School', label: 'TECHVOC, ASSOCIATE PROGRAM, BSPsych, Graduate School' },
      { value: 'TECHVOC', label: 'TECHVOC' },
      { value: 'ASSOCIATE PROGRAM', label: 'ASSOCIATE PROGRAM' },
      { value: 'BSPsych', label: 'BSPsych' },
      { value: 'Graduate School', label: 'Graduate School' }
    ],
    4: [
      { value: 'BSED,BEED,BSIT,BSCS', label: 'BSED, BEED, BSIT, BSCS' },
      { value: 'BSED', label: 'BSED' },
      { value: 'BEED', label: 'BEED' },
      { value: 'BSIT', label: 'BSIT' },
      { value: 'BSCS', label: 'BSCS' }
    ],
    5: [
      { value: 'PNC SHS', label: 'PNC SHS' }
    ]
  };

  const serviceOptions = [
    { value: 'Document Request', label: 'Document Request' },
    { value: 'Requirement Submission for Enrollment', label: 'Requirement Submission for Enrollment' },
    { value: 'CTC/Others', label: 'CTC/Others' },
    { value: 'Terminal Clearance', label: 'Terminal Clearance' },
    { value: 'Application for Graduation', label: 'Application for Graduation' },
    { value: 'Releasing of Documents', label: 'Releasing of Documents' }
  ];

  const generatePDF = () => {
    const input = tableRef.current;
    const backgroundPath = `${process.env.PUBLIC_URL}/UC.png`;
    const logoPath = `${process.env.PUBLIC_URL}/pnc-logo.png`;

    const background = new Image();
    background.src = backgroundPath;

    const logo = new Image();
    logo.src = logoPath;

    background.onload = () => {
      html2canvas(input, { scale: 2 }).then((canvas) => {
        const pdf = new jsPDF("landscape", "pt", "a4");

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const bgImgWidth = 150;
        const bgImgHeight = 40;
        const logoWidth = 60;
        const logoHeight = 60;
        const rowHeight = 20; // Adjust this as needed
        // Adjust column widths based on your content needs
        const colWidths = [80, 90, 90, 210, 80, 80, 80, 80];

        const tableRows = transactions.length;
        const rowsPerPage = Math.floor((pageHeight - 300) / rowHeight);
        let totalPages = Math.ceil(tableRows / rowsPerPage);

        const addHeader = (page) => {
          pdf.addImage(logo.src, "PNG", 250, 25, logoWidth, logoHeight);
          pdf.setFontSize(10);
          pdf.text("Republic of the Philippines", pageWidth / 2, 30, null, null, "center");
          pdf.setFont("helvetica", "bold");
          pdf.setTextColor(0, 102, 0);
          pdf.setFontSize(12);
          pdf.text("Pamantasan ng Cabuyao", pageWidth / 2, 45, null, null, "center");
          pdf.setTextColor(0, 0, 0);
          pdf.setFont("helvetica", "normal");
          pdf.text("(University of Cabuyao)", pageWidth / 2, 60, null, null, "center");
          pdf.setFont("helvetica", "italic", "bold");
          pdf.text("Academic Affairs Division", pageWidth / 2, 75, null, null, "center");
          pdf.text("Office of the University Registrar", pageWidth / 2, 90, null, null, "center");
          pdf.setFontSize(9);
          pdf.setFont("helvetica", "italic");
          pdf.text("Katapatan Mutual Homes, Brgy. Banay-banay, City of Cabuyao, Laguna 4025", pageWidth / 2, 105, null, null, "center");

          // Add footer
          pdf.setFontSize(10);
          pdf.text(`Page ${page} of ${totalPages}`, 40, pageHeight - 30);
        };

        const addDocumentInfo = () => {
          pdf.setFontSize(11);
          pdf.setFont('helvetica', 'bold');
          pdf.text('Program:', 40, 150);
          pdf.setFont('helvetica', 'normal');
          pdf.text(`${department}`, 95, 150);
          pdf.setFont('helvetica', 'bold');
          pdf.text('Date:', 150, 150);
          // Check if the user has filtered by a specific date. If so, use it; otherwise, use the current date.
          const formattedDate = date ? new Date(date).toLocaleDateString() : new Date().toLocaleDateString();
          pdf.setFont('helvetica', 'normal');
          pdf.text(`${formattedDate}`, 180, 150);
          pdf.setFont('helvetica', 'bold');
          pdf.text('Document:', 260, 150);
          pdf.setFont('helvetica', 'normal');
          pdf.text(`${service}`, 330, 150);
        
          // Display total number of transactions
          pdf.setFont('helvetica', 'bold');
          pdf.text('Total Transactions:', 550, 150); // Adjust positioning as necessary
          pdf.setFont('helvetica', 'normal');
          pdf.text(`${transactions.length}`, 660, 150); // Use the length of the transactions array
        };
        

        const addTableHeader = () => {
          pdf.setFontSize(10);
          pdf.setFont("helvetica", "bold");
          const headers = ["Queue #", "Name", "Department", "Service", "Date", "Start Time", "End Time", "Status"];
          let xPos = 40;
          headers.forEach((header, index) => {
            pdf.text(header, xPos, 190);
            xPos += colWidths[index];
          });
        };

        const drawTableRowLines = (yPosition) => {
          const startX = 40;
          const endX = pageWidth - 40;
          pdf.setLineWidth(0.5);
          pdf.line(startX, yPosition, endX, yPosition);  // Draw a single horizontal line at the start of the row
          pdf.line(startX, yPosition + rowHeight, endX, yPosition + rowHeight);  // Draw a single horizontal line at the end of the row
        };

        for (let page = 1; page <= totalPages; page++) {
          if (page > 1) pdf.addPage();

          pdf.setGState(new pdf.GState({ opacity: 0.1 }));
          for (let y = 0; y < pageHeight + bgImgHeight; y += bgImgHeight) {
            for (let x = -bgImgWidth; x < pageWidth; x += bgImgWidth) {
              pdf.addImage(background.src, "PNG", x, y, bgImgWidth, bgImgHeight, "", "FAST", 25);
            }
          }
          pdf.setGState(new pdf.GState({ opacity: 1 }));

          addHeader(page);
          addDocumentInfo();
          addTableHeader();

          const startRow = (page - 1) * rowsPerPage;
          const endRow = Math.min(startRow + rowsPerPage, tableRows);

          pdf.setFontSize(10);
          pdf.setFont("helvetica", "normal");

          for (let row = startRow; row < endRow; row++) {
            const transaction = transactions[row];
            const yPosition = 200 + (row - startRow) * rowHeight;

            drawTableRowLines(yPosition);

            let xPos = 40;
            pdf.text(`${transaction.queueNumber}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[0];
            pdf.text(`${transaction.name}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[1];
            pdf.text(`${transaction.department}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[2];
            pdf.text(`${transaction.service}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[3];
            pdf.text(`${new Date(transaction.transactionDate).toLocaleDateString()}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[4];
            pdf.text(`${transaction.startTimeOfService}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[5];
            pdf.text(`${transaction.endTimeOfService}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[6];
            pdf.text(`${transaction.status}`, xPos, yPosition + rowHeight / 2);
          }

          drawTableRowLines(200 + (endRow - startRow) * rowHeight);
        }

        pdf.save("transaction-report.pdf");
      });

      handleCloseModal();
    };
  };    

  // CSV export configuration
  const csvHeaders = [
    {label: "Transaction Id", key: "transactionId"},
    { label: "Queue No.", key: "queueNumber" },
    { label: "Name", key: "name" },
    { label: "Department", key: "department" },
    { label: "Service", key: "service" },
    { label: "Date", key: "transactionDate" },
    { label: "Start of Service", key: "startTimeOfService" },
    { label: "End of Service", key: "endTimeOfService" },
    { label: "Status", key: "status" }
  ];

  return (
    <div className="transaction-table-container">
      <Row className="mb-4">
        <Col>
          <Form.Control
            type="text"
            placeholder="Search by Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
        <Col>
          <FilterDropdown
            label="Department"
            options={departmentOptions[windowNum]}
            onChange={(e) => setDepartment(e.target.value)}
          />
        </Col>
        <Col>
          <FilterDropdown
            label="Service/Document"
            options={serviceOptions}
            onChange={(e) => setService(e.target.value)}
          />
        </Col>
        <Col>
          <Form.Control
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Col>
        <Col>
          <Button variant="success" onClick={handleOpenModal} className="print-button">EXPORT</Button>
        </Col>
      </Row>

      <div className="transaction-table-wrapper" id="transaction-table" ref={tableRef}>
        <Table className="transaction-table" hover>
          <thead>
            <tr>
              <th>Queue #</th>
              <th>Name</th> 
              <th>Department</th>
              <th>Service</th>
              <th>Date</th>
              <th>Start of Service</th>
              <th>End of Service</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.queueNumber}</td>
                <td>{transaction.name}</td>
                <td>{transaction.department}</td>
                <td>{transaction.service}</td>
                <td>{new Date(transaction.transactionDate).toLocaleDateString()}</td>
                <td>{transaction.startTimeOfService}</td>
                <td>{transaction.endTimeOfService}</td>
                <td>{transaction.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modal for exporting options */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Export Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="primary" onClick={generatePDF} className="mr-3">Export to PDF</Button>
          <CSVLink
            data={transactions}
            headers={csvHeaders}
            filename={"transaction-report.csv"}
            className="btn btn-secondary"
            onClick={handleCloseModal}
          >
            Export to CSV
          </CSVLink>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TransactionTable;
