import React, { useState, useEffect, useRef } from 'react';
import './LiveQueueingDisplay.css';
import { io } from 'socket.io-client';

const LiveQueueingDisplay = () => {
  const [currentQueue, setCurrentQueue] = useState([]);
  const [dateTime, setDateTime] = useState(new Date());
  const [videoUrl, setVideoUrl] = useState(null); // Video URL for the video section
  const announcements = useRef({});

  const socket = useRef(null);

  useEffect(() => {
    // Initialize WebSocket connection
    socket.current = io('https://qless-server.onrender.com', {
      withCredentials: true, // Include cookies for session-based authentication
    });

    // Fetch initial data (current queue and video)
    const fetchInitialData = async () => {
      try {
        const queueResponse = await fetch('https://qless-server.onrender.com/api/currentQueue', {
          credentials: 'include', // Include cookies with request for session-based auth
        });
        if (queueResponse.ok) {
          const queueData = await queueResponse.json();
          setCurrentQueue(queueData);
        } else {
          console.error('Error fetching queue data:', queueResponse.status);
        }

        const videoResponse = await fetch('https://qless-server.onrender.com/api/getCurrentVideo', {
          credentials: 'include', // Include cookies with request
        });
        if (videoResponse.ok) {
          const videoData = await videoResponse.json();
          setVideoUrl(videoData.videoPath);
        } else {
          console.error('Error fetching video data:', videoResponse.status);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();

    // WebSocket: Handle queue updates
    socket.current.on('queueUpdate', (data) => {
      console.log('Data received:', data);
  
      setCurrentQueue((prevQueues) => {
          // Filter out queues with "done", "canceled", or "hold" status
          if (['done', 'canceled', 'hold'].includes(data.status) || !data.queueNumber || !data.windowNum) {
              return prevQueues.filter((queue) => queue.queueId !== data.queueId);
          }
  
          // Filter out previous entries for the same window
          const filteredQueues = prevQueues.filter((queue) => queue.windowNum !== data.windowNum);
  
          // Add the new queue data
          return [...filteredQueues, { ...data, updatedAt: new Date().getTime() }];
      });
    });  

    // WebSocket: Handle video updates
    socket.current.on('videoUpdate', (data) => {
      console.log('Received video update via WebSocket:', data);
      setVideoUrl(data.videoPath);
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect(); // Clean up WebSocket connection
      }
    };
  }, []);

  useEffect(() => {
    currentQueue.forEach((queue) => {
      if (queue.status === 'resume' && announcements.current[queue.queueNumber] >= 2) {
        // Reset the announcement counter if the queue was on hold and resumed
        announcements.current[queue.queueNumber] = 0;
      }
  
      if (!announcements.current[queue.queueNumber]) {
        announcements.current[queue.queueNumber] = 0;
      }
  
      if (announcements.current[queue.queueNumber] < 2) {
        console.log(`Announcing queue number ${queue.queueNumber}`);
        const utterance = new SpeechSynthesisUtterance(
          `Number ${queue.queueNumber}, please proceed to Window ${queue.windowNum}`
        );
        window.speechSynthesis.speak(utterance);
        announcements.current[queue.queueNumber] += 1;
      }
    });
  }, [currentQueue]);
  

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const renderQueueItems = () => {
    const windows = ['W1', 'W2', 'W3', 'W4', 'W5','W6'];
    return windows.map((window, index) => {
      const windowNumber = index + 1;
      const queue = Array.isArray(currentQueue) ? currentQueue.find((item) => item.windowNum === windowNumber) : null;
      return (
        <div key={index} className="queue-item">
          <div className="window">{window}</div>
          <div className="queue-number">{queue ? queue.queueNumber : 'N/A'}</div>
        </div>
      );
    });
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div className="queue-display">
      <header className="queue-header">
        <h1>UNIVERSITY OF CABUYAO QUEUEING SYSTEM</h1>
      </header>
      <div className="queue-content">
        <div className="left-section">
          <div className="now-serving">
            <h2>Now Serving:</h2>
            <div className="now-serving-inner">
            <div className="queue-number">
              {currentQueue.length > 0
                ? currentQueue.reduce((latest, current) => (latest.updatedAt > current.updatedAt ? latest : current)).queueNumber
                : 'None'}
            </div>
            <div className="window-number">
              {currentQueue.length > 0
                ? `W${currentQueue.reduce((latest, current) => (latest.updatedAt > current.updatedAt ? latest : current)).windowNum}`
                : ''}
            </div>
            </div>
          </div>
          <div className="video-section">
            {videoUrl && (
              <video width="100%" autoPlay loop>
                <source src={`https://qless-server.onrender.com/${videoUrl}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
        <div className="right-section">
          <div className="queue-list">{renderQueueItems()}</div>
        </div>
      </div>
      <footer className="queue-footer">
        <div className="date-time">
          <img src="/qless.png" alt="Logo" className="footer-logo" />
          {formatDate(dateTime)}
        </div>
      </footer>
    </div>
  );
};

export default LiveQueueingDisplay;
