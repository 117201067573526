import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Modal, Form, Alert, InputGroup  } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import Sidebar from '../Sidebar';
import DashboardNavbar from '../Navbar';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './UserAccounts.css';

const UserAccounts = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterRole, setFilterRole] = useState('All');
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('Staff');
  const [windowNum, setWindowNum] = useState('1');
  const [status, setStatus] = useState('Active');
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    axios
      .get('https://qless-server.onrender.com/api/registrars', {
        withCredentials: true, // Send HTTP-only cookies with request
      })
      .then((response) => {
        setUsers(response.data);
        setFilteredUsers(response.data); // Initialize filtered users
      })
      .catch((error) => {
        console.error('There was an error fetching the user data!', error);
      });
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    filterUsers(event.target.value, filterRole);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
    filterUsers(searchTerm, event.target.value);
  };

  const filterUsers = (searchTerm, role) => {
    let filtered = users;

    if (searchTerm) {
      filtered = filtered.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (role !== 'All') {
      filtered = filtered.filter(user => user.role === role);
    }

    setFilteredUsers(filtered);
  };

  const handleAddUser = () => {
    if (password !== confirmPassword) {
      setAlert(
        <SweetAlert
          danger
          title="Password Mismatch!"
          onConfirm={() => setAlert(null)}
        >
          Passwords do not match!
        </SweetAlert>
      );
      return;
    }
  
    const newUser = {
      username,
      firstName,
      lastName,
      password,
      role,
      windowNum,
      status,
      fullName: `${firstName} ${lastName}`, // Concatenate fullName for display purposes
    };
  
    if (editMode) {
      axios
        .put(
          `https://qless-server.onrender.com/api/registrars/${selectedUserId}`, 
          newUser, 
          { withCredentials: true }
        )
        .then((response) => {
          const updatedUser = {
            ...response.data,
            fullName: `${response.data.firstName} ${response.data.lastName}`,
          };
  
          // Update state with the edited user data
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.registrarId === selectedUserId ? { ...user, ...updatedUser } : user
            )
          );
          setFilteredUsers((prevFilteredUsers) =>
            prevFilteredUsers.map((user) =>
              user.registrarId === selectedUserId ? { ...user, ...updatedUser } : user
            )
          );
  
          setAlert(
            <SweetAlert
              success
              title="User Updated!"
              onConfirm={() => setAlert(null)}
            >
              The user was updated successfully!
            </SweetAlert>
          );
          setShowModal(false);
          setEditMode(false);
          setSelectedUserId(null);
        })
        .catch((error) => {
          console.error('There was an error updating the user!', error);
          setAlert(
            <SweetAlert
              danger
              title="Update Failed!"
              onConfirm={() => setAlert(null)}
            >
              Failed to update the user.
            </SweetAlert>
          );
        });
    } else {
      axios
        .post(
          'https://qless-server.onrender.com/api/registrars', 
          newUser, 
          { withCredentials: true }
        )
        .then((response) => {
          const addedUser = {
            ...response.data,
            fullName: `${response.data.firstName} ${response.data.lastName}`,
          };
  
          // Add new user to the state
          setUsers((prevUsers) => [...prevUsers, addedUser]);
          setFilteredUsers((prevFilteredUsers) => [...prevFilteredUsers, addedUser]);
  
          setAlert(
            <SweetAlert
              success
              title="User Added!"
              onConfirm={() => setAlert(null)}
            >
              The user was added successfully!
            </SweetAlert>
          );
          setShowModal(false);
        })
        .catch((error) => {
          console.error('There was an error adding the user!', error);
          setAlert(
            <SweetAlert
              danger
              title="Addition Failed!"
              onConfirm={() => setAlert(null)}
            >
              Failed to add the user.
            </SweetAlert>
          );
        });
    }
  };

  // Update handleDeleteUser function
  const handleDeleteUser = (id) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="light"
        cancelBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          axios.delete(`https://qless-server.onrender.com/api/registrars/${id}`, {
            withCredentials: true,
          })
            .then(() => {
              setUsers((prevUsers) => prevUsers.filter((user) => user.registrarId !== id));
              setFilteredUsers((prevFilteredUsers) => prevFilteredUsers.filter((user) => user.registrarId !== id));
              setAlert(<SweetAlert success title="Deleted!" onConfirm={() => setAlert(null)}>User has been deleted successfully.</SweetAlert>);
            })
            .catch((error) => {
              console.error('Error deleting user:', error);
              setAlert(<SweetAlert danger title="Failed to delete!" onConfirm={() => setAlert(null)}>An error occurred while deleting the user.</SweetAlert>);
            });
        }}
        onCancel={() => setAlert(null)}
      >
        You won't be able to revert this!
      </SweetAlert>
    );
  };

  const handleEditUser = (user) => {
    setSelectedUserId(user.registrarId);
    setUsername(user.username);
    setFirstName(user.fullName.split(' ')[0]);
    setLastName(user.fullName.split(' ')[1]);
    setPassword(user.password);
    setConfirmPassword(user.password);
    setRole(user.role);
    setWindowNum(user.windowNum);
    setStatus(user.status);
    setEditMode(true);
    setShowModal(true);
  };

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md={2} className="p-0">
          <Sidebar role="Admin" />
        </Col>
        <Col md={10} className="p-0">
          <DashboardNavbar role="Admin" />
          <Container className="mt-4">
            <div className="search-filter-container">
              <div className="search-box">
                <input
                  type="text"
                  placeholder="Search by name"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <div className="filter-box">
                <select value={filterRole} onChange={handleFilterRole}>
                  <option value="All">All Roles</option>
                  <option value="Admin">Admin</option>
                  <option value="Staff">Staff</option>
                </select>
              </div>
              <div className="add-new-user-btn">
                <Button
                  variant="success"
                  onClick={() => {
                    setShowModal(true);
                    setEditMode(false);
                    setSelectedUserId(null);
                    setUsername('');
                    setFirstName('');
                    setLastName('');
                    setPassword('');
                    setConfirmPassword('');
                    setRole('Staff');
                    setWindowNum('1');
                  }}
                >
                  Add New User
                </Button>
              </div>
            </div>
            <Row className="mb-4">
              <Col md={12}>
                <Table className="user-table" hover>
                  <thead>
                    <tr>
                      <th>Window</th>
                      <th>Username</th>
                      <th>Full Name</th>
                      <th>Logged in</th>
                      <th>Logged out</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user) => (
                      <tr key={user.registrarId}>
                        <td>{user.windowNum}</td>
                        <td>{user.username}</td>
                        <td>{user.fullName}</td>
                        <td>{user.loggedIn}</td>
                        <td>{user.loggedOut}</td>
                        <td>{user.role}</td>
                        <td>{user.status}</td>
                        <td>
                          <Button variant="info" size="sm" className="button-spacing" onClick={() => handleEditUser(user)}>
                            Edit
                          </Button>
                          <Button variant="danger" size="sm" onClick={() => handleDeleteUser(user.registrarId)}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      {/* Modal for Adding/Editing User */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? 'Edit User' : 'Account Information'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <div className="position-relative">
                  <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="password-input"
                  />
                  <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
              </div>
            </Form.Group>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)}>
                <option>Staff</option>
                <option>Admin</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formWindowNum">
              <Form.Label>Window Number</Form.Label>
              <Form.Control as="select" value={windowNum} onChange={(e) => setWindowNum(e.target.value)}>
                <option value="1">Window 1</option>
                <option value="2">Window 2</option>
                <option value="3">Window 3</option>
                <option value="4">Window 4</option>
                <option value="5">Window 5</option>
                <option value="6">Window 6</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option>Active</option>
                <option>Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleAddUser}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserAccounts;