import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import DashboardNavbar from './Navbar';
import TransactionTable from './TransactionTable';
import './Transaction.css';

const Transaction = ({ user }) => {
  return (
    <Container fluid className="transaction-container">
      <Row noGutters>
        <Col md={2} className="p-0">
          <Sidebar windowNum={user.windowNum} />
        </Col>
        <Col md={10} className="p-0">
          <DashboardNavbar />
          <Container fluid className="transaction-content">
            <Row className="mt-4">
              <Col>
                <TransactionTable windowNum={user.windowNum} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Transaction;