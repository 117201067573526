import React from 'react';
import { Form } from 'react-bootstrap';

const FilterDropdown = ({ label, options = [], onChange }) => {  // Default value for options is an empty array
  return (
    <Form.Group controlId={`form${label}`}>
      <Form.Label>{label}</Form.Label>
      <Form.Control as="select" onChange={onChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default FilterDropdown;
