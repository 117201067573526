import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import './Dashboard.css';

const Sidebar = ({ role, windowNum }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showAlert, setShowAlert] = useState(false);
  const [user, setUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch logged-in user details from localStorage or session
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post('https://qless-server.onrender.com/logout', {}, {
        withCredentials: true, // Ensures the request includes the session cookie
      });
      localStorage.removeItem('user'); // Clear localStorage
      localStorage.removeItem('token'); // Clear JWT token from localStorage
      window.location.href = 'https://qless.site'; // Redirect directly
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const confirmLogout = () => {
    setShowAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, log me out"
        cancelBtnText="Cancel"
        confirmBtnBsStyle="danger"
        title="Are you sure you want to logout?"
        onConfirm={() => {
          setShowAlert(null); // Close alert
          handleLogout(); // Proceed with logout
        }}
        onCancel={() => setShowAlert(null)} // Close alert on cancel
      >
        You will be logged out of your account.
      </SweetAlert>
    );
  };

  const getAdminLinks = () => (
    <ul>
      <li className={location.pathname === "/admin/dashboard" ? "active" : ""}>
        <Link to="/admin/dashboard">Dashboard</Link>
      </li>
      <li className={location.pathname === "/admin/user-accounts" ? "active" : ""}>
        <Link to="/admin/user-accounts">User Accounts</Link>
      </li>
      <li className={location.pathname === "/admin/records" ? "active" : ""}>
        <Link to="/admin/records">Records</Link>
      </li>
      <li className={location.pathname === "/admin/manage-display" ? "active" : ""}>
        <Link to="/admin/manage-display">Manage Display</Link>
      </li>
      <li className={location.pathname === "/admin/transaction-days" ? "active" : ""}>
        <Link to="/admin/transaction-days">Transaction Days</Link>
      </li>
    </ul>
  );

  const getStaffLinks = () => (
    <ul>
      <li className={location.pathname === "/dashboard" ? "active" : ""}>
        <Link to="/dashboard">Dashboard</Link>
      </li>
      <li className={location.pathname === "/transaction" ? "active" : ""}>
        <Link to="/transaction">Transaction</Link>
      </li>
      <li className={location.pathname === "/manage-display" ? "active" : ""}>
        <Link to="/manage-display">Manage Display</Link>
      </li>
    </ul>
  );

  return (
    <div className="sidebar">
      {showAlert}
      <div className="sidebar-top">
        <p>{currentTime.toLocaleDateString()} {currentTime.toLocaleTimeString()}</p>
        <Image src="/qless-logo.png" alt="Logo" className="sidebar-logo" fluid />
      </div>
      <div className="sidebar-user">
        <Image src="/user.png" alt="User" fluid />
        <p>{role === 'Admin' ? 'Super Admin' : `Window ${windowNum}`}</p>
        {user && <p>{user.firstName} {user.lastName}</p>} {/* Display full name */}
      </div>
      <nav className="sidebar-nav">
        {role === 'Admin' ? getAdminLinks() : getStaffLinks()}
      </nav>
      <div className="sidebar-bottom">
        <p onClick={confirmLogout} style={{ cursor: 'pointer' }}>Logout</p>
      </div>
    </div>
  );
};

export default Sidebar;