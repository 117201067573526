import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Table, Alert } from 'react-bootstrap';
import Sidebar from './Sidebar';
import DashboardNavbar from './Navbar';
import axios from 'axios';
import { io } from 'socket.io-client';
import './ManageDisplay.css'; 

const ManageDisplay = ({ user }) => {
  const [videos, setVideos] = useState([]);
  const [currentVideoPath, setCurrentVideoPath] = useState(null);
  const [alert, setAlert] = useState(null);
  const socket = useRef(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('https://qless-server.onrender.com/api/videos', {
          withCredentials: true, // Automatically include cookies
        });
        setVideos(response.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    const fetchCurrentVideo = async () => {
      try {
        const response = await axios.get('https://qless-server.onrender.com/api/getCurrentVideo', {
          withCredentials: true, // Automatically include cookies
        });
        setCurrentVideoPath(response.data.videoPath);
      } catch (error) {
        console.error('Error fetching current video:', error);
      }
    };

    fetchVideos();
    fetchCurrentVideo();

    // Initialize WebSocket connection
    socket.current = io('https://qless-server.onrender.com', {
      withCredentials: true, // Automatically include cookies for WebSocket
    });

    // Listen for video updates
    socket.current.on('videoUpdate', (data) => {
      console.log('Received video update:', data);
      setCurrentVideoPath(data.videoPath);
      fetchVideos(); // Update the videos when a new video is added
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();  // Clean up WebSocket connection
      }
    };
  }, []);

  const handleShowVideo = async (videoPath) => {
    if (currentVideoPath) {
      setAlert("You can't show more than one video at a time.");
      return;
    }

    try {
      await axios.post('https://qless-server.onrender.com/api/showVideo', { videoPath }, {
        withCredentials: true, // Automatically include cookies
      });
      setCurrentVideoPath(videoPath);
    } catch (error) {
      console.error('Error sending video path to Live Queueing Display:', error);
    }
  };

  const handleUnshowVideo = async () => {
    try {
      await axios.post('https://qless-server.onrender.com/api/unshowVideo', {}, {
        withCredentials: true, // Automatically include cookies
      });
      setCurrentVideoPath(null);
    } catch (error) {
      console.error('Error removing video from Live Queueing Display:', error);
    }
  };

  const handleShowLiveQueueing = () => {
    window.open("/live-queueing-display", "_blank");
  };

  return (
    <Container fluid className="dashboard-container">
      <Row noGutters>
        <Col md={2} className="p-0">
          <Sidebar windowNum={user.windowNum} />
        </Col>
        <Col md={10} className="p-0">
          <DashboardNavbar />
          <Container fluid className="dashboard-content">
            {alert && <Alert variant="danger" onClose={() => setAlert(null)} dismissible>{alert}</Alert>}
            <Row className="mt-4 justify-content-end"> 
              <Col xs="auto"> 
                <Button
                  variant="warning"
                  size="lg"  
                  style={{ backgroundColor: '#E7EB20', borderColor: '#198754', color: '#198754' }} 
                  onClick={handleShowLiveQueueing}
                >
                  Show Live Queueing
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <div className="table-container">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Description</th>
                        <th>Duration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {videos.map((video, index) => (
                        <tr key={video.id}>
                          <td>{index + 1}</td>
                          <td>{video.description}</td>
                          <td>{video.duration}</td>
                          <td>
                            {currentVideoPath === video.filePath ? (
                              <Button variant="danger" onClick={handleUnshowVideo}>Unshow</Button>
                            ) : (
                              <Button variant="success" onClick={() => handleShowVideo(video.filePath)}>Show</Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageDisplay;