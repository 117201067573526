import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import Sidebar from '../Sidebar';
import DashboardNavbar from '../Navbar';
import SweetAlert from 'react-bootstrap-sweetalert';
import './AdminManageDisplay.css';
import axios from 'axios';
import { io } from 'socket.io-client';

const AdminManageDisplay = ({ user }) => {
  const [videos, setVideos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newVideo, setNewVideo] = useState({
    description: '',
    duration: '',
    file: null,
  });
  const [alert, setAlert] = useState(null);
  const socket = useRef(null);

  // Define fetchVideos function outside the useEffect to make it reusable
  const fetchVideos = async () => {
    try {
      const response = await axios.get('https://qless-server.onrender.com/api/videos', {
        withCredentials: true, // Send the HTTP-only cookies with the request
      });
      setVideos(response.data);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  // Fetch videos and initialize WebSocket on component mount
  useEffect(() => {
    fetchVideos();

    // Initialize WebSocket connection
    socket.current = io('https://qless-server.onrender.com', {
      withCredentials: true, // Include cookies with WebSocket connection for session-based auth
    });

    // Listen for video updates
    socket.current.on('videoUpdate', (data) => {
      console.log('Received video update:', data);
      fetchVideos(); // Re-fetch the videos when a video is added/removed
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect(); // Clean up WebSocket connection
      }
    };
  }, []);

  const handleDelete = async (id) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="light"
        title="Are you sure?"
        onConfirm={async () => {
          try {
            await axios.delete(`https://qless-server.onrender.com/api/videos/${id}`, {
              withCredentials: true, // Send the HTTP-only cookies with the request
            });
            setAlert(<SweetAlert success title="Deleted!" onConfirm={() => setAlert(null)}>The video has been deleted.</SweetAlert>);
            // Emit videoUpdate event for WebSocket listeners
            socket.current.emit('videoUpdate', { videoPath: '' });
            fetchVideos();
          } catch (error) {
            console.error('Error deleting video:', error);
          }
        }}
        onCancel={() => setAlert(null)}
      >
        You won't be able to revert this!
      </SweetAlert>
    );
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        setNewVideo({
          ...newVideo,
          file: file,
          duration: new Date(duration * 1000).toISOString().substr(11, 8), // Convert seconds to HH:MM:SS
        });
      };

      video.src = URL.createObjectURL(file);
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('description', newVideo.description);
    formData.append('duration', newVideo.duration);
    formData.append('file', newVideo.file);

    try {
      await axios.post('https://qless-server.onrender.com/api/videos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true, // Send the HTTP-only cookies with the request
      });
      // Emit videoUpdate event for WebSocket listeners
      socket.current.emit('videoUpdate', { videoPath: newVideo.file.path });
      setAlert(<SweetAlert success title="Success!" onConfirm={() => setAlert(null)}>Video has been added successfully.</SweetAlert>);
      fetchVideos();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving video:', error);
    }
  };

  return (
    <Container fluid className="admin-manage-display-container">
      {alert}
      <Row noGutters>
        <Col md={2} className="p-0">
          <Sidebar role="Admin" />
        </Col>
        <Col md={10} className="p-0">
          <DashboardNavbar />
          <Container fluid className="admin-manage-display-content">
            <Row className="mt-4">
              <Col>
                <Button 
                  variant="primary" 
                  onClick={handleShowModal} 
                  className="add-video-button"
                >
                  Add Video
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Duration</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {videos.map((video, index) => (
                      <tr key={video.id}>
                        <td>{index + 1}</td>
                        <td>{video.description}</td>
                        <td>{video.duration}</td>
                        <td>
                          <Button 
                            variant="danger" 
                            onClick={() => handleDelete(video.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="videoDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter video description"
                value={newVideo.description}
                onChange={(e) => setNewVideo({ ...newVideo, description: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="videoDuration">
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="text"
                placeholder="Video duration will be auto-determined"
                value={newVideo.duration}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="videoFile">
              <Form.Label>File</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={handleSave}>Save</Button>
        </Modal.Footer> 
      </Modal>
    </Container>
  );
};

export default AdminManageDisplay;
