import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const DashboardNavbar = () => {
  const location = useLocation();
  
  const getBrandText = () => {
    switch (location.pathname) {
      case '/dashboard':
        return 'Dashboard';
      case '/transaction':
        return 'Transaction';
      case '/manage-display':
        return 'Manage Display';
      case '/settings':
        return 'Settings';
      case '/admin/activity-monitoring-screen':
        return 'Activity Monitoring Screen (Real-time Update)';
      case '/admin/user-accounts':
        return 'User Accounts';
      case '/admin/records':
        return 'Records';
      case '/admin/manage-display':
        return 'ManageDisplay';
      case '/admin/transaction-days':
        return 'Transaction Days';
      default:
        return 'Dashboard';
    }
  };

  return (
    <Navbar bg="success" variant="dark" className="navbar-custom">
      <Container fluid>
        <Navbar.Brand className="ms-0">
          <img
            src="/pnc-logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Logo"
          />
          {getBrandText()}
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default DashboardNavbar;