import React, { useState, useEffect } from "react";
import { Container, Table, Button, Modal } from "react-bootstrap";
import axios from "axios";
import DashboardNavbar from '../Navbar';
import "./ActivityMonitoringScreen.css";

const ActivityMonitoringScreen = ({ user }) => {
  const [windowsData, setWindowsData] = useState([]);
  const [detailedData, setDetailedData] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);
  const [showTeamModal, setShowTeamModal] = useState(false); // Modal state
  const [teamPerformanceData, setTeamPerformanceData] = useState([]); // Team performance data state

  useEffect(() => {
    fetchWindowsData();
  }, []);

  // Fetch windows data using HTTP-only cookies
  const fetchWindowsData = () => {
    axios
      .get("https://qless-server.onrender.com/api/windows-data", {
        withCredentials: true, // Ensure the HTTP-only cookies are sent with the request
      })
      .then((response) => {
        setWindowsData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the windows data!", error);
      });
  };

  // Fetch detailed data for a specific window
  const fetchDetailedData = (windowNum) => {
    if (expandedRows.includes(windowNum)) {
      setExpandedRows(expandedRows.filter((num) => num !== windowNum));
      return;
    }

    axios
      .get(`https://qless-server.onrender.com/api/window-details/${windowNum}`, {
        withCredentials: true, // Ensure the HTTP-only cookies are sent with the request
      })
      .then((response) => {
        setDetailedData((prevData) => ({
          ...prevData,
          [windowNum]: response.data,
        }));
        setExpandedRows([...expandedRows, windowNum]);
      })
      .catch((error) => {
        console.error("There was an error fetching the detailed data!", error);
      });
  };

  const fetchTeamPerformanceData = () => {
    axios
      .get("https://qless-server.onrender.com/api/team-performance", {
        withCredentials: true,
      })
      .then((response) => {
        setTeamPerformanceData(response.data); // Set the fetched team performance data
      })
      .catch((error) => {
        console.error("Error fetching team performance data:", error);
      });
  };

  const handleShowTeamModal = () => {
    fetchTeamPerformanceData(); // Fetch team performance data when the modal is opened
    setShowTeamModal(true);
  };

  // Handle closing of the team performance modal
  const handleCloseTeamModal = () => {
    setShowTeamModal(false);
  };

  return (
    <Container fluid className="activity-monitoring-container">
      <DashboardNavbar />
      <div className="button-container">
        <Button className="button-back" onClick={() => window.history.back()}>
          Back to Dashboard
        </Button>
        <Button className="button-team" onClick={handleShowTeamModal}>
          View Team Performance
        </Button>
      </div>
      <div className="scrollable-table-container">
        <Table className="activity-monitoring-table" bordered>
          <thead>
            <tr>
              <th>Window</th>
              <th>Total Queued</th>
              <th>Total Served</th>
              <th>Total Left</th>
              <th>Cancelled</th>
              <th>No Show</th>
              <th>Average Service Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {windowsData.map((data, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{data.window}</td>
                  <td>{data.totalQueued}</td>
                  <td>{data.totalServed}</td>
                  <td>{data.totalLeft}</td>
                  <td>{data.cancelled}</td>
                  <td>{data.noShow}</td>
                  <td>{parseFloat(data.averageServiceTime).toFixed(2)} min</td>
                  <td>
                    <Button
                      className="button-details"
                      onClick={() => fetchDetailedData(data.windowNum)}
                    >
                      {expandedRows.includes(data.windowNum) ? "Hide Details" : "View Details"}
                    </Button>
                  </td>
                </tr>
                {expandedRows.includes(data.windowNum) &&
                  detailedData[data.windowNum]?.map((detail, i) => (
                    <tr key={i} className="details-row">
                      <td>{detail.department}</td>
                      <td>{detail.totalQueued}</td>
                      <td>{detail.totalServed}</td>
                      <td>{detail.totalLeft}</td>
                      <td>{detail.cancelled}</td>
                      <td>{detail.noShow}</td>
                      <td>{parseFloat(detail.averageServiceTime).toFixed(2)} min</td>
                      <td></td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>

       {/* Modal for Team Performance */}
      <Modal show={showTeamModal} onHide={handleCloseTeamModal} centered dialogClassName="custom-modal-centered">
        <Modal.Header closeButton>
          <Modal.Title>Team Performance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="team-performance-table" bordered>
            <thead>
              <tr>
                <th>Team Member</th>
                <th>Window</th>
                <th>Served</th>
                <th>Cancelled</th>
                <th>Total Queued</th>
                <th>Average Service Time</th>
              </tr>
            </thead>
            <tbody>
              {teamPerformanceData.map((team, index) => (
                <tr key={index}>
                  <td>{team.teamMember}</td>
                  <td>{team.windowName}</td>
                  <td>{team.totalServed}</td>
                  <td>{team.totalCancelled}</td>
                  <td>{team.totalQueued}</td>
                  <td>{parseFloat(team.averageServiceTime).toFixed(2)} min</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

    </Container>
  );
};
  
export default ActivityMonitoringScreen;
