import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Table, Button, Form, Modal } from 'react-bootstrap';
import Sidebar from '../Sidebar';
import DashboardNavbar from '../Navbar';
import './AdminRecords.css';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CSVLink } from 'react-csv'; // For CSV export

const AdminRecords = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [windowNum, setWindowNum] = useState('');
  const [service, setService] = useState('');
  const [showModal, setShowModal] = useState(false); // Modal state for export options
  const tableRef = useRef(); // To reference the table for PDF generation

  useEffect(() => {
    const params = {
      windowNum: windowNum || undefined,
      service: service || undefined,
    };

    axios.get('https://qless-server.onrender.com/api/adminTransactions', {
      withCredentials: true, // Send HTTP-only cookies with the request
      params
    })
    .then(response => {
      setRecords(response.data);
    })
    .catch(error => {
      console.error('Error fetching records:', error);
    });
  }, [windowNum, service]);

  // Open and close modal functions
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const generatePDF = () => {
    const input = tableRef.current;

    // Load the background image and logo from the public directory
    const backgroundPath = `${process.env.PUBLIC_URL}/UC.png`;
    const logoPath = `${process.env.PUBLIC_URL}/pnc-logo.png`;

    const background = new Image();
    background.src = backgroundPath;

    const logo = new Image();
    logo.src = logoPath;

    background.onload = () => {
      html2canvas(input, { scale: 2 }).then(canvas => {
        const pdf = new jsPDF('landscape', 'pt', 'a4'); 

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const bgImgWidth = 150; 
        const bgImgHeight = 40; 
        const logoWidth = 60;  // Adjust the logo width
        const logoHeight = 60; // Adjust the logo height
        const rowHeight = 20; // Adjust this as needed
        // Adjust column widths based on your content needs
        const colWidths = [80, 70, 110, 90, 200, 80, 80, 80];
  
        const tableRows = records.length;
        const rowsPerPage = Math.floor((pageHeight - 300) / rowHeight);
        let totalPages = Math.ceil(tableRows / rowsPerPage);

        const addHeader = (page) => {
          pdf.addImage(logo.src, "PNG", 250, 25, logoWidth, logoHeight);
          pdf.setFontSize(10);
          pdf.text("Republic of the Philippines", pageWidth / 2, 30, null, null, "center");
          pdf.setFont("helvetica", "bold");
          pdf.setTextColor(0, 102, 0);
          pdf.setFontSize(12);
          pdf.text("Pamantasan ng Cabuyao", pageWidth / 2, 45, null, null, "center");
          pdf.setTextColor(0, 0, 0);
          pdf.setFont("helvetica", "normal");
          pdf.text("(University of Cabuyao)", pageWidth / 2, 60, null, null, "center");
          pdf.setFont("helvetica", "italic", "bold");
          pdf.text("Academic Affairs Division", pageWidth / 2, 75, null, null, "center");
          pdf.text("Office of the University Registrar", pageWidth / 2, 90, null, null, "center");
          pdf.setFontSize(9);
          pdf.setFont("helvetica", "italic");
          pdf.text("Katapatan Mutual Homes, Brgy. Banay-banay, City of Cabuyao, Laguna 4025", pageWidth / 2, 105, null, null, "center");
  
          // Add footer
          pdf.setFontSize(10);
          pdf.text(`Page ${page} of ${totalPages}`, 40, pageHeight - 30);
        };

        const addDocumentInfo = () => {
          pdf.setFontSize(11);
          pdf.setFont('helvetica', 'bold');
          pdf.text('Window No.:', 40, 150);
          pdf.setFont('helvetica', 'normal');
          pdf.text(`${windowNum}`, 110, 150);
         
          pdf.setFont('helvetica', 'bold');
          pdf.text('Service:', 250, 150);
          pdf.setFont('helvetica', 'normal');
          pdf.text(`${service}`, 300, 150);
         
          // Display total number of transactions
          pdf.setFont('helvetica', 'bold');
          pdf.text('Total Records:', 550, 150); // Adjust positioning as necessary
          pdf.setFont('helvetica', 'normal');
          pdf.text(`${records.length}`, 630, 150); // Use the length of the transactions array
        };
        
        const addTableHeader = () => {
          pdf.setFontSize(10);
          pdf.setFont("helvetica", "bold");
          const headers = ["Queue No.", "Window", "Name", "Department", "Service", "Date", "Start Time", "End Time"];
          let xPos = 40;
          headers.forEach((header, index) => {
            pdf.text(header, xPos, 190);
            xPos += colWidths[index];
          });
        };

        const drawTableRowLines = (yPosition) => {
          const startX = 40;
          const endX = pageWidth - 40;
          pdf.setLineWidth(0.5);
          pdf.line(startX, yPosition, endX, yPosition);  // Draw a single horizontal line at the start of the row
          pdf.line(startX, yPosition + rowHeight, endX, yPosition + rowHeight);  // Draw a single horizontal line at the end of the row
        };

        for (let page = 1; page <= totalPages; page++) {
          if (page > 1) pdf.addPage();
  
          pdf.setGState(new pdf.GState({ opacity: 0.1 }));
          for (let y = 0; y < pageHeight + bgImgHeight; y += bgImgHeight) {
            for (let x = -bgImgWidth; x < pageWidth; x += bgImgWidth) {
              pdf.addImage(background.src, "PNG", x, y, bgImgWidth, bgImgHeight, "", "FAST", 25);
            }
          }
          pdf.setGState(new pdf.GState({ opacity: 1 }));
  
          addHeader(page);
          addDocumentInfo();
          addTableHeader();
  
          const startRow = (page - 1) * rowsPerPage;
          const endRow = Math.min(startRow + rowsPerPage, tableRows);
  
          pdf.setFontSize(10);
          pdf.setFont("helvetica", "normal");
  
          for (let row = startRow; row < endRow; row++) {
            const record = records[row];
            const yPosition = 200 + (row - startRow) * rowHeight;
  
            drawTableRowLines(yPosition);
  
            let xPos = 40;
            pdf.text(`${record.queueNumber}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[0];
            pdf.text(`${record.windowNum}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[1];
            pdf.text(`${record.name}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[2];
            pdf.text(`${record.department}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[3];
            pdf.text(`${record.service}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[4];
            pdf.text(`${new Date(record.transactionDate).toLocaleDateString()}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[5];
            pdf.text(`${record.startTimeOfService}`, xPos, yPosition + rowHeight / 2);
            xPos += colWidths[6];
            pdf.text(`${record.endTimeOfService}`, xPos, yPosition + rowHeight / 2);
          }
  
          drawTableRowLines(200 + (endRow - startRow) * rowHeight);
        }
  
        pdf.save("admin-records.pdf");
      });
  
      handleCloseModal();
    };
  };

  // CSV export configuration
  const csvHeaders = [
    { label: "Queue No.", key: "queueNumber" },
    { label: "Window", key: "windowNum" },
    { label: "Name", key: "name" },
    { label: "Program", key: "department" },
    { label: "Service", key: "service" },
    { label: "Date (in Queue)", key: "transactionDate" },
    { label: "Start of Service", key: "startTimeOfService" },
    { label: "End of Service", key: "endTimeOfService" }
  ];

  return (
    <Container fluid className="admin-records-container">
      <Row noGutters>
        <Col md={2} className="p-0">
          <Sidebar role="Admin" />
        </Col>
        <Col md={10} className="p-0">
          <DashboardNavbar />
          <Container fluid className="records-content">
            <Row className="mt-4">
              <Col>
                <Form className="filter-form">
                  <Row>
                    <Col md={4}>
                      <Form.Group controlId="filterWindow">
                        <Form.Label>Window Number</Form.Label>
                        <Form.Control as="select" value={windowNum} onChange={e => setWindowNum(e.target.value)} className="custom-select">
                          <option value="">Select Window</option>
                          <option value="1">Window 1</option>
                          <option value="2">Window 2</option>
                          <option value="3">Window 3</option>
                          <option value="4">Window 4</option>
                          <option value="5">Window 5</option>
                          <option value="6">Window 6</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="filterService">
                        <Form.Label>Service</Form.Label>
                        <Form.Control as="select" value={service} onChange={e => setService(e.target.value)} className="custom-select">
                          <option value="">Select Service</option>
                          <option value="Document Request">Document Request</option>
                          <option value="Requirement Submission for Enrollment">Requirement Submission for Enrollment</option>
                          <option value="CTC/Others">CTC/OTHERS</option>
                          <option value="Terminal Clearance">Terminal Clearance</option>
                          <option value="Application for Graduation">Application for Graduation</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    
                  </Row>
                      {/* Export Button */}
                <Button variant="success" onClick={handleOpenModal} className="print-button">EXPORT</Button>

                </Form>

                <div className="table-container" ref={tableRef}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Queue No.</th>
                        <th>Window</th>
                        <th>Name</th>
                        <th>Program</th>
                        <th>Service</th>
                        <th>Date (in Queue)</th>
                        <th>Time of Service (Start)</th>
                        <th>Time of Service (End)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((record, index) => (
                        <tr key={index}>
                          <td>{record.queueNumber}</td>
                          <td>{record.windowNum}</td>
                          <td>{record.name}</td>
                          <td>{record.department}</td>
                          <td>{record.service}</td>
                          <td>{new Date(record.transactionDate).toLocaleDateString()}</td>
                          <td>{record.startTimeOfService}</td>
                          <td>{record.endTimeOfService}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {/* Modal for export options */}
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Export Options</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Button variant="primary" onClick={generatePDF} className="mr-3">Export to PDF</Button>
                    <CSVLink
                      data={records}
                      headers={csvHeaders}
                      filename={"admin-records.csv"}
                      className="btn btn-secondary"
                      onClick={handleCloseModal}
                    >
                      Export to CSV
                    </CSVLink>
                  </Modal.Body>
                </Modal>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminRecords;
