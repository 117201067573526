import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { Line, Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from '../Navbar';
import Sidebar from '../Sidebar';
import './AdminDashboard.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import io from 'socket.io-client';

// Register the necessary components for Chart.js
Chart.register(...registerables);

const socket = io('https://qless-server.onrender.com'); // Connect to the WebSocket server

const AlertModal = ({ show, handleClose, handleSubmit }) => {
  const [message, setMessage] = useState('');

  const sendAlert = () => {
    handleSubmit(message);
    setMessage('');
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Alert Announcement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="alertMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter message to send"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={sendAlert}>
          Send Alert
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AdminDashboard = ({ user }) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState('');
  const [timeFrame, setTimeFrame] = useState('day');
  const [waitingTimeData, setWaitingTimeData] = useState({
    labels: [],
    datasets: []
  });
  const [processingTimeData, setProcessingTimeData] = useState({
    labels: [],
    datasets: []
  });

  const [stats, setStats] = useState({
    queued: 0,
    onHold: 0,
    served: 0,
    cancelled: 0
  });

  const [servicesData, setServicesData] = useState([]);

  // Fetch services data
  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        const response = await axios.get('https://qless-server.onrender.com/api/servicesData', {
          withCredentials: true  // Use session cookies
        });
        setServicesData(response.data); // This should correctly set the services data
        console.log('Fetched services data:', response.data); // Debugging: Ensure data is fetched
      } catch (error) {
        console.error('Failed to fetch services data:', error);
      }
    };

    fetchServicesData();
    const interval = setInterval(fetchServicesData, 60000); // Refresh services data every minute
    return () => clearInterval(interval);
  }, []);

  // Fetch statistics with session ID
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('https://qless-server.onrender.com/api/dailySummary', {
          withCredentials: true,  // Session cookies
        });
        setStats(response.data); // Set the stats
      } catch (error) {
        console.error('Failed to fetch daily stats:', error);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 60000); // Refresh stats every minute
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchHourlyStats = async () => {
      try {
        const response = await axios.get('https://qless-server.onrender.com/api/hourlyStats', {
          withCredentials: true  // Session cookies
        });
        const data = response.data;

        if (data.waitingTimes && data.processingTimes) {
          setWaitingTimeData({
            labels: data.waitingTimes.map(d => `Window ${d.window_number}`),
            datasets: [{
              label: 'Average Waiting Time (by Window)',
              data: data.waitingTimes.map(d => parseFloat(d.averageWaitingTime)),
              borderColor: '#dc3545',
              backgroundColor: 'rgba(220, 53, 69, 0.2)',
              fill: true
            }]
          });

          setProcessingTimeData({
            labels: data.processingTimes.map(d => d.prefix),
            datasets: [{
              label: 'Average Processing Time (by Service)',
              data: data.processingTimes.map(d => parseFloat(d.averageProcessingTime)),
              borderColor: '#007bff',
              backgroundColor: 'rgba(0, 123, 255, 0.2)',
              fill: true
            }]
          });
        }
      } catch (error) {
        console.error('Failed to fetch hourly stats:', error);
      }
    };

    fetchHourlyStats();
    const interval = setInterval(fetchHourlyStats, 60000); // Refresh stats every minute
    return () => clearInterval(interval);
  }, []);

  const processingTimeOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { title: { display: true, text: 'Services' } },
      y: {
        beginAtZero: true,
        title: { display: true, text: 'Minutes' }
      }
    },
    plugins: {
      legend: { display: true, position: 'top' },
      tooltip: { mode: 'index', intersect: false }
    }
  };

  const waitingTimeOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { title: { display: true, text: 'Windows' } },
      y: {
        beginAtZero: true,
        title: { display: true, text: 'Minutes' }
      }
    },
    plugins: {
      legend: { display: true, position: 'top' },
      tooltip: { mode: 'index', intersect: false }
    }
  };

  const [barData, setBarData] = useState({
    labels: [],
    datasets: [{
      label: 'Queue Length',
      data: [],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }],
  });

  useEffect(() => {
    const fetchQueueData = async () => {
      try {
        const response = await axios.get('https://qless-server.onrender.com/api/queueData', {
          withCredentials: true  // Session cookies
        });
        const data = response.data;

        const updateBarData = (frame) => {
          let labels = [];
          let queueCounts = [];

          if (frame === 'day') {
            labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
            queueCounts = Array(24).fill(0);
            data.dailyQueueData.forEach(item => {
              queueCounts[item.hour] = item.queueCount;
            });
          } else if (frame === 'week') {
            labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            queueCounts = Array(7).fill(0);
            data.weeklyQueueData.forEach(item => {
              queueCounts[item.dayOfWeek - 1] = item.queueCount;
            });
          } else if (frame === 'month') {
            labels = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
            queueCounts = Array(5).fill(0);
            data.monthlyQueueData.forEach(item => {
              queueCounts[item.weekOfMonth - 1] = item.queueCount;
            });
          }

          setBarData({
            labels: labels,
            datasets: [{
              label: 'Queue Length',
              data: queueCounts,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
            }],
          });
        };

        updateBarData(timeFrame);

      } catch (error) {
        console.error('Failed to fetch queue data:', error);
      }
    };

    fetchQueueData();
    const interval = setInterval(fetchQueueData, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [timeFrame]);

  const handleTimeFrameChange = (frame) => {
    setTimeFrame(frame);
  };

  const barOptions = {
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const [groupedbarData, setgroupedBarData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    const fetchGroupedQueueData = async () => {
      try {
        const response = await axios.get('https://qless-server.onrender.com/api/groupedQueueData', {
          withCredentials: true  // Session cookies
        });
        const data = response.data;

        if (data.length > 0) {
          const windows = [...new Set(data.map(item => item.window_number))];
          const services = [...new Set(data.map(item => item.prefix))];

          const datasets = services.map((service, index) => ({
            label: service,
            data: windows.map(window => {
              const found = data.find(item => item.window_number === window && item.prefix === service);
              return found ? found.queue_length : 0;
            }),
            backgroundColor: ['#ACE1AF', '#17B169', '#1CAC78', '#018749', '#00563B'][index % 5],
            borderColor: ['#ACE1AF', '#17B169', '#1CAC78', '#018749', '#00563B'][index % 5],
            borderWidth: 1,
          }));

          setgroupedBarData({
            labels: windows.map(window => `Window ${window}`),
            datasets: datasets
          });
        } else {
          console.log('No data received from API');
        }
      } catch (error) {
        console.error('Failed to fetch grouped queue data:', error);
      }
    };

    fetchGroupedQueueData();
    const interval = setInterval(fetchGroupedQueueData, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [timeFrame]);

  const groupedBarOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: { stacked: false },
      y: { stacked: false, beginAtZero: true, title: { display: true, text: 'Queue Length' } }
    },
    plugins: {
      legend: { display: true, position: 'top' },
      tooltip: { mode: 'index', intersect: false }
    }
  };

  const handleSendAlert = (message) => {
    socket.emit('sendAlert', { message });
  };

  // Utility function to handle line breaks in text
  const addTextWithLineBreaks = (pdf, text, xPos, yPos, lineHeight) => {
    const lines = text.split('\n'); // Split text by line breaks
    lines.forEach((line, index) => {
      pdf.text(line, xPos, yPos + (index * lineHeight)); // Adjust y-position for each line
    });
  };
  
  // Function to generate the report
  const generateReport = async (stats, servicesData) => {
    const charts = document.querySelectorAll('.chart-container');

    if (!charts.length) {
      console.error('No charts found on the page.');
      return;
    }

    const backgroundPath = `${process.env.PUBLIC_URL}/UC.png`;
    const logoPath = `${process.env.PUBLIC_URL}/pnc-logo.png`;

    const background = new Image();
    background.src = backgroundPath;

    const logo = new Image();
    logo.src = logoPath;

    background.onload = async () => {
      try {
        const pdf = new jsPDF('landscape', 'pt', 'a4');

        const addHeader = (pdf) => {
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          const bgImgWidth = 150;
          const bgImgHeight = 40;
          const logoWidth = 60;
          const logoHeight = 60;

          // Set transparency level for background image
          pdf.setGState(new pdf.GState({ opacity: 0.1 }));

          // Tile the background image across the entire page with rotation
          for (let y = 0; y < pageHeight + bgImgHeight; y += bgImgHeight) {
            for (let x = -bgImgWidth; x < pageWidth; x += bgImgWidth) {
              pdf.addImage(background.src, 'PNG', x, y, bgImgWidth, bgImgHeight, '', 'FAST', 25); 
            }
          }

          // Reset transparency level
          pdf.setGState(new pdf.GState({ opacity: 1 }));

          // Add the logo to the PDF aligned to the left
          pdf.addImage(logo.src, 'PNG', 250, 25, logoWidth, logoHeight);

          // Center the text block
          pdf.setFontSize(10);

          let text = 'Republic of the Philippines';
          let textWidth = pdf.getTextWidth(text);
          pdf.text(text, (pageWidth - textWidth) / 2, 30);

          pdf.setFont('helvetica', 'bold');
          pdf.setTextColor(0, 102, 0); 
          text = 'Pamantasan ng Cabuyao';
          textWidth = pdf.getTextWidth(text);
          pdf.setFontSize(12);
          pdf.text(text, (pageWidth - textWidth) / 2 - 12, 45);

          pdf.setTextColor(0, 0, 0);
          pdf.setFont('helvetica', 'normal');
          text = '(University of Cabuyao)';
          pdf.setFontSize(12);
          textWidth = pdf.getTextWidth(text);
          pdf.text(text, (pageWidth - textWidth) / 2, 60);

          pdf.setFont('helvetica', 'italic', 'bold');
          text = 'Academic Affairs Division';
          textWidth = pdf.getTextWidth(text);
          pdf.text(text, (pageWidth - textWidth) / 2, 75);

          pdf.setFont('helvetica', 'italic', 'bold');
          text = 'Office of the University Registrar';
          textWidth = pdf.getTextWidth(text);
          pdf.text(text, (pageWidth - textWidth) / 2, 90);

          pdf.setFontSize(9);
          pdf.setFont('helvetica', 'italic');
          text = 'Katapatan Mutual Homes, Brgy. Banay-banay, City of Cabuyao, Laguna 4025';
          textWidth = pdf.getTextWidth(text);
          pdf.text(text, (pageWidth - textWidth) / 2, 105);

          // Adjust this return value to provide more space for the table after the header
          return 130; // Adjust this value based on the required spacing after the header
        };

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        let currentY = addHeader(pdf); // Add header and get Y position for the table

        // Add text for today's stats
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`Today's Queued: ${stats.queued}`, 40, currentY + 10);
        pdf.text(`Today's Served: ${stats.served}`, 230, currentY + 10);
        pdf.text(`Today's On Hold: ${stats.onHold}`, 400, currentY + 10);
        pdf.text(`Today's Cancelled: ${stats.cancelled}`, 600, currentY + 10);

        currentY += 50; // Add space before starting the charts

        // Loop over each chart and add it to the PDF
        const marginX = 10; 
        const marginY = 180; 
        const chartSpacing = 20; 
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const halfWidth = (pdfWidth - marginX * 4) / 2; 

        for (let i = 0; i < charts.length; i++) {
          const canvas = charts[i].querySelector('canvas');
          const chartInstance = Chart.getChart(canvas); 

          const chartData = chartInstance.data.datasets[0].data;
          const chartLabels = chartInstance.data.labels;
          const maxData = Math.max(...chartData);
          const minData = Math.min(...chartData);
          const avgData = (chartData.reduce((sum, val) => sum + val, 0) / chartData.length).toFixed(2);

          const chartType = chartInstance.config.type;
          let analysisText = '';

          if (chartType === 'line') {
            if (chartInstance.data.datasets[0].label.includes('Processing Time')) {
              analysisText = `The line chart shows the average processing time by service, with a maximum \nof ${maxData} minutes, minimum of ${minData} minutes, and an average of ${avgData} minutes.`;
            } else if (chartInstance.data.datasets[0].label.includes('Waiting Time')) {
              analysisText = `The line chart shows the average waiting time by window, with a maximum \nof ${maxData} minutes, minimum of ${minData} minutes, and an average of ${avgData} minutes.`;
            }
          } else if (chartType === 'bar') {
            if (chartInstance.data.datasets[0].label === 'Queue Length') {
              const peakIndex = chartData.indexOf(maxData);
              const peakLabel = chartLabels[peakIndex];
              analysisText = `The bar chart shows the number of queues entering every ${timeFrame === 'day' ? 'hour' : timeFrame === 'week' ? 'day' : 'week'}, with a peak \nof ${maxData} queues at ${peakLabel}.`;
            } else {
              analysisText = `This bar chart represents the queue length in different windows and services.`;
            }
          }

          // Render the chart to an image and add it to the PDF
          const chartCanvas = await html2canvas(charts[i], { backgroundColor: '#ffffff' });
          const imgData = chartCanvas.toDataURL('image/png');

          const imgProps = pdf.getImageProperties(imgData);

          const maxHeight = 150;
          let imgWidth = halfWidth;
          let imgHeight = imgProps.height * (halfWidth / imgProps.width);

          if (imgHeight > maxHeight) {
            imgHeight = maxHeight;
          }

          let xPos = marginX;
          let yPos = marginY;

          if (i % 2 === 1) {
            xPos = marginX + halfWidth + chartSpacing;
          }

          yPos = marginY + Math.floor(i / 2) * (maxHeight + chartSpacing);

          pdf.addImage(imgData, 'PNG', xPos, yPos, imgWidth, imgHeight);

          // Add the chart analysis text just below the chart
          pdf.setFontSize(10.5);
          pdf.setFont('helvetica', 'italic', 'bold');
          addTextWithLineBreaks(pdf, analysisText, xPos, yPos + imgHeight + 10, 10);
        }

        pdf.addPage();
        currentY = addHeader(pdf); // Add header to the new page and reset the Y position

        currentY +=-80;

        const addServicesTable = (pdf, servicesData, pageWidth, startY, tableTitle) => {
          if (!servicesData || servicesData.length === 0) {
            console.error('No services data available to display.');
            return;
          }

          pdf.setFontSize(12);
          pdf.setFont('helvetica', 'bold');
          pdf.setTextColor(0, 0, 0); 
          pdf.text(tableTitle, pageWidth / 2, startY - 10, { align: 'center' });

          pdf.setFont('helvetica', 'normal');
          pdf.setFontSize(10);

          let currentY = startY + 50; 
          const rowHeight = 25; 
          const columnWidths = {
            queue: 40,
            name: 170,
            department: 290,
            transactionDate: 390,
            startTimeOfService: 510,
            endTimeOfService: 630,
            status: 750,
          };

          // Group servicesData by service prefix (e.g., "CT", "DR")
          const groupedServices = servicesData.reduce((acc, service) => {
            const prefix = service.service || 'N/A';
            if (!acc[prefix]) acc[prefix] = [];
            acc[prefix].push(service);
            return acc;
          }, {});

          Object.keys(groupedServices).forEach((servicePrefix) => {
            if (currentY >= pdf.internal.pageSize.height - 30) {
              pdf.addPage();
              currentY = addHeader(pdf) + 10; // Add space after header on the new page
            }

            const serviceCount = groupedServices[servicePrefix].length;
            currentY += 30;
            pdf.setFont('helvetica', 'bold');
            pdf.text(`Service: ${servicePrefix} (Total: ${serviceCount})`, 40, currentY);
            currentY += 25;

            // Table headers
            pdf.text('Queue Number', columnWidths.queue + 5, currentY);
            pdf.text('Name', columnWidths.name + 5, currentY);
            pdf.text('Program', columnWidths.department + 5, currentY);
            pdf.text('Transaction Date', columnWidths.transactionDate + 5, currentY);
            pdf.text('Start Time', columnWidths.startTimeOfService + 5, currentY);
            pdf.text('End Time', columnWidths.endTimeOfService + 5, currentY);
            pdf.text('Status', columnWidths.status + 5, currentY);

            currentY += 5;
            pdf.setDrawColor(0);
            pdf.setFont('helvetica', 'bold');
            pdf.line(40, currentY, pageWidth - 30, currentY);

            groupedServices[servicePrefix].forEach((service) => {
              if (currentY >= pdf.internal.pageSize.height - 30) {
                pdf.addPage();
                currentY = addHeader(pdf) -5; // Add space after header on the new page
              }

              currentY += rowHeight;
              pdf.text(service.queueNumber, columnWidths.queue + 5, currentY);
              pdf.text(service.name, columnWidths.name + 5, currentY);
              pdf.text(service.department, columnWidths.department + 5, currentY);
              pdf.text(new Date(service.transactionDate).toLocaleDateString(), columnWidths.transactionDate + 5, currentY);

              const startTime = new Date(`1970-01-01T${service.startTimeOfService}`);
              const endTime = new Date(`1970-01-01T${service.endTimeOfService}`);

              pdf.text(startTime.toLocaleTimeString(), columnWidths.startTimeOfService + 5, currentY);
              pdf.text(endTime.toLocaleTimeString(), columnWidths.endTimeOfService + 5, currentY);
              pdf.text(service.status, columnWidths.status + 5, currentY);

              currentY += 5;
              pdf.line(40, currentY, pageWidth - 30, currentY);
            });

            currentY += 15;
          });
        };

        addServicesTable(pdf, servicesData, pageWidth, currentY, '');

        // Save the PDF
        pdf.save('generated_report.pdf');
      } catch (error) {
        console.error('Failed to create PDF:', error);
      }
    };
  };

  return (
    <Container fluid className="admin-dashboard-container">
      <Row noGutters>
        <Col md={2} className="p-0">
          <Sidebar role="Admin" />
        </Col>
        <Col md={10} className="p-0">
          <DashboardNavbar role="Admin" />
          <Container className="dashboard-content mt-4">
            <Row className="mb-3 monitoring">
              <Col md={12} className="text-center">
                <Button className="monitoringBtn" onClick={() => navigate('/admin/activity-monitoring-screen')}>
                  Activity Monitoring Screen
                </Button>
                <Button className="monitoringBtn" onClick={() => setModalShow(true)}>
                  Alert Announcement
                </Button>
              </Col>
              <Button variant="success" className="generateBtn" onClick={() => {
                    if (stats && Object.keys(stats).length > 0 && servicesData) {
                      generateReport(stats, servicesData);
                    } else {
                      console.error('Stats or Services data not available yet.');
                    }
                  }}>
                    Generate Report
                  </Button>
            </Row>
            
            <Row className="mb-6">
              <Col md={3}>
                <Card className="dashboard-card large-card" border="success">
                  <Card.Body>
                    <Card.Title>Today's Queued</Card.Title>
                    <h1>{stats.queued}</h1> 
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="dashboard-card large-card" border="success">
                  <Card.Body>
                    <Card.Title>Today's Served</Card.Title>
                    <h1>{stats.served}</h1>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="dashboard-card large-card" border="success">
                  <Card.Body>
                    <Card.Title>Today's Hold</Card.Title>
                    <h1>{stats.onHold}</h1>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="dashboard-card large-card" border="success">
                  <Card.Body>
                    <Card.Title>Today's Cancelled </Card.Title>
                    <h1>{stats.cancelled}</h1>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col md={6}>
                <div className="chart-container">
                  <Line data={processingTimeData} options={processingTimeOptions} />
                </div>
              </Col>
              <Col md={6}>
                <div className="chart-container">
                  <Line data={waitingTimeData} options={waitingTimeOptions} />
                </div>
              </Col>
            </Row>

            <Row className="mt-7">
              <Col md={6}>
                <div className="chart-container mt-3">
                  <Bar data={barData} options={barOptions} />
                  <div className="d-flex justify-content-around mt-2">
                    <Button className="green-button" onClick={() => handleTimeFrameChange('day')}>Day</Button>
                    <Button className="green-button" onClick={() => handleTimeFrameChange('week')}>Week</Button>
                    <Button className="green-button" onClick={() => handleTimeFrameChange('month')}>Month</Button>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="chart-container mt-3">
                  <Bar data={groupedbarData} options={groupedBarOptions} />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <AlertModal show={modalShow} handleClose={() => setModalShow(false)} handleSubmit={handleSendAlert} />
    </Container>
  );
};

export default AdminDashboard;
